import { Suspense, lazy, useState, useEffect } from 'react';
import { ApolloProvider, useQuery } from '@apollo/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PrivateRoute from './components/Organisms/Route/PrivateRoute';
import { ME_QUERY }  from './components/Queries/User';
import SiteError from './components/Molecules/Error/SiteError';
import SiteLoader from './components/Molecules/SiteLoader/SiteLoader';
import { useAppApolloClient } from './config/apolloClient';
import { useAuthToken, useLogout } from './config/auth';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { useLogoutMutation } from './components/Mutations/Auth';
import { ENV_QUERY } from './components/Queries/Env';

const Dashboard = lazy(() => import('./components/Pages/Dashboard'));
const Vehicle = lazy(() => import('./components/Pages/Vehicle'));
const Make = lazy(() => import('./components/Pages/Make'));
const Market = lazy(() => import('./components/Pages/Market'));
const Model = lazy(() => import('./components/Pages/Model'));
const Login = lazy(() => import('./components/Pages/Login'));
const RequestPassword = lazy(() => import('./components/Pages/RequestPassword'));
const ResetPassword = lazy(() => import('./components/Pages/ResetPassword'));
const Register = lazy(() => import('./components/Pages/Register'));
const SettingsUser = lazy(() => import('./components/Pages/Settings/User'));
/*
const More = lazy(() => import('./components/Pages/More'));
const Notifications = lazy(() => import('./components/Pages/Notifications'));
const Notification = lazy(() => import('./components/Pages/Notification'));
*/
const VerifyEmail = lazy(() => import('./components/Pages/VerifyEmail'));
const ChangeEmail = lazy(() => import('./components/Pages/ChangeEmail'));

function App(){
	const [isInit, setIsInit] = useState(false);

	i18n.use(LanguageDetector).use(initReactI18next).init({
	    detection:{
	        checkWhitelist:true
	    },
	    debug:false,
	    interpolation:{
	        escapeValue:false
	    }
	}, () => {
		setIsInit(true);
	});

	return isInit ? <Container i18n={i18n} /> : <SiteLoader />;
}

const Container = (props) => {
	const client = useAppApolloClient(props.i18n);

	return (
        <ApolloProvider client={client}>
	        <Wrapper i18n={props.i18n} />
	    </ApolloProvider>
    );
}

const Wrapper = (props) => {
	const [getToken] = useAuthToken();
	const { logout } = useLogoutMutation();
    const removeToken = useLogout();
	const { data:dataEnv } = useQuery(ENV_QUERY);
	const { loading:loadingMe, error:errorMe } = useQuery(ME_QUERY, {
		onCompleted:(data) => {
			if (!data.me && getToken){
				removeToken();
				logout({
					update:() => {
						window.location.href = '/';
			        }
				});
			}
		}
	});

	useEffect(() => {
		if (dataEnv){
			props.i18n.addResourceBundle(props.i18n.language, ['translation'], dataEnv.env.translations);
		}
	}, [dataEnv]);

	const globalProps = {
		colorize:true,
        collapsed:true,
		hideSidebar:false,
		hideTopbar:false,
		additionalSidebar:false,
		mode:'default',
		background:true
    };

	return (
		<BrowserRouter>
        	{loadingMe ?
        		<SiteLoader />
        	:
        		<>
        			{!errorMe ?
	        			<Suspense fallback={<SiteLoader />}>
				        	<Routes>
								<Route exact path="/" element={<PrivateRoute><Dashboard {...globalProps} background={true} /></PrivateRoute>} />
								<Route exact path="/markets/:market/:make/:model/:vehicleId" element={<PrivateRoute><Vehicle {...globalProps} /></PrivateRoute>} />
								<Route exact path="/markets/:market/:make/:model" element={<PrivateRoute><Model {...globalProps} /></PrivateRoute>} />
								<Route exact path="/markets/:market/:make" element={<PrivateRoute><Make {...globalProps} /></PrivateRoute>} />
								<Route exact path="/markets/:market" element={<PrivateRoute><Market {...globalProps} /></PrivateRoute>} />
								<Route exact path="/login" element={<Login {...globalProps} />} />
								<Route exact path="/request-password" element={<RequestPassword {...globalProps} />} />
								<Route exact path="/reset-password" element={<ResetPassword {...globalProps} />} />
								<Route exact path="/register" element={<Register {...globalProps} />} />
								<Route exact path="/settings/user" element={<PrivateRoute><SettingsUser {...globalProps} /></PrivateRoute>} />
								{/*
								<Route exact path="/more" element={<PrivateRoute><More {...globalProps} /></PrivateRoute>} />
								<Route exact path="/notifications" element={<PrivateRoute><Notifications {...globalProps} /></PrivateRoute>} />
								<Route exact path="/notifications/:id/single" element={<PrivateRoute><Notification {...globalProps} /></PrivateRoute>} />
								*/}								
								<Route exact path="/verify-email" element={<VerifyEmail {...globalProps} />} />
								<Route exact path="/change-email" element={<ChangeEmail {...globalProps} />} />
				        	</Routes>
				        </Suspense>
				    :
				    	<SiteError />
				    }
        		</>
        	}
        </BrowserRouter>
	);
}

export default App;