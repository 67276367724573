import { gql } from '@apollo/client';

export const ME_QUERY = gql`
    query MeQuery {
        me {
            id
            name
            customerId
            email
            emailVerified
            workingTeam {
                id
                name
            }
            roles {
                id
                name
            }
            isAbleTo
            todoList {
                id
                slug
                name
            }
            brandGroups {
                id
                name
                slug
            }
            tags {
                id
                name
                reminderAt
                notes
            }
            tooltips {
                id
                name
                tour {
                    id
                    name
                }
            }
            unreadNotifications
        }
    }
`;

export const ADMIN_USERS_TABLE_QUERY = gql`
    query AdminUsersTableQuery($first:Int!, $page:Int!, $filter:AdminUsersFilter, $sortColumn:String, $sortDirection:SortDirection){
        adminUsers(first:$first, page:$page, filter:$filter, sortColumn:$sortColumn, sortDirection:$sortDirection){
            data {
                id
                name
                email
                customerId
                tags {
                    name
                    reminderAt
                    notes
                }
                createdAt
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                perPage
                total
            }
        }
    }
`;

export const ADMIN_USER_BY_ID_QUERY = gql`
    query AdminUserQuery($id:ID!){
        adminUser(id:$id){
            id
            name
            roles {
                id
            }
            permissions {
                id
            }
        }
    }
`;