import { Fragment, forwardRef } from 'react';
import SVG from 'react-inlinesvg';
import styles from './Button.module.scss';
import Spinner from '../Spinner/Spinner';
import { CSVLink } from 'react-csv';
import Tooltip from '../Tooltip/Tooltip';
import { isExternalLink } from '../../Utils/Helper';
import { Link } from 'react-router-dom';

const Button = forwardRef((props, ref) => {
	function classNames(){
		const classNames = [styles.Wrapper];
		if (props.className){
			classNames.push(props.className);
		}
		if (props.color){
			classNames.push(styles['Wrapper--'+props.color]);
		}
		if (props.rounded){
			if (props.rounded === 'md'){
				classNames.push(styles['Wrapper--rounded']);
			} else if (props.rounded === 'md-l'){
				classNames.push(styles['Wrapper--rounded-l']);
			} else if (props.rounded === 'md-r'){
				classNames.push(styles['Wrapper--rounded-r']);
			} else if (props.rounded === 'full-l'){
				classNames.push(styles['Wrapper--rounded-full-l']);
			} else if (props.rounded === 'full-r'){
				classNames.push(styles['Wrapper--rounded-full-r']);
			} else if (props.rounded === 'full'){
				classNames.push(styles['Wrapper--rounded-full']);
			}
		}
		if (!props.value && props.icon){
			classNames.push(styles['Wrapper--icon-only']);
		}
		if (props.outline){
			classNames.push(styles['Wrapper--outline']);
		}
		if (props.size){
			classNames.push(styles['Wrapper--'+props.size]);
		}
		if (props.block){
			classNames.push(styles['Wrapper--block']);
		}
		if (props.text){
			classNames.push(styles['Wrapper--text']);
		}
		if (props.active){
			classNames.push(styles['Wrapper--active']);
		}
		if (props.loading || props.disabled){
			classNames.push(styles['Wrapper--disabled']);
		}
		if (props.shadow === false){
			classNames.push(styles['Wrapper--no-shadow']);
		}
		if (props.padding === false){
			classNames.push(styles['Wrapper--no-padding']);
		}
		return classNames.join(' ');
	}

	return (
		<>
			{props.to ?
				<>
					{(isExternalLink(props.to) || props.target === '_blank') ?
						<a href={props.to} className={classNames()} target={props.target || '_self'} onClick={props.onClick} onMouseDown={props.onMouseDown} disabled={props.loading || props.disabled ? true : null} aria-label={props.ariaLabel || null} {...props.attributes} ref={ref}>
							<ButtonBody {...props} />
						</a>
					:
						<Link to={props.to} className={classNames()} target={props.target || '_self'} onClick={props.onClick} onMouseDown={props.onMouseDown} disabled={props.loading || props.disabled ? true : null} aria-label={props.ariaLabel || null} {...props.attributes} ref={ref}>
							<ButtonBody {...props} />
						</Link>
					}
				</>
			:
				<>
					{props.data ?
						<CSVLink className={classNames()} onClick={props.onClick} onMouseDown={props.onMouseDown} disabled={props.loading || props.disabled ? true : null} data={props.data} title={props.title} filename={props.filename} aria-label={props.ariaLabel || null} {...props.attributes} ref={ref}>
							<ButtonBody {...props} />
						</CSVLink>
					:
						<button type={props.type || 'button'} form={props.form || null} className={classNames()} onClick={props.onClick} onMouseDown={props.onMouseDown} disabled={props.loading || props.disabled ? true : null} aria-label={props.ariaLabel || null} {...props.attributes} ref={ref}>
							<ButtonBody {...props} />
							{props.type == 'file' &&
								<input type="file" id={props.id} accept={props.accept} multiple={props.multiple} {...props.register} onChange={props.onChange} className={styles.File} />
							}
						</button>
					}
				</>
			}
		</>
	)
});

const ButtonBody = (props) => {
	const CustomTag = props.tooltip ? Tooltip : Fragment;
	const customProps = props.tooltip ? {text:props.tooltip} : {};
	return (
		<CustomTag {...customProps}>
			<div className={styles.Body}>
				{props.icon && (props.iconPos === undefined || props.iconPos == 'before') && <div className={styles.Icon}><SVG src={process.env.PUBLIC_URL+'/img/icons/'+props.icon+'.svg'} /></div>}
				{props.value && <div className={styles.Value}>{props.value}</div>}
				{props.icon && props.iconPos == 'after' && <div className={styles.Icon}><SVG src={process.env.PUBLIC_URL+'/img/icons/'+props.icon+'.svg'} /></div>}
				{props.arrow && <div className={styles.Arrow}><span></span></div>}
				{props.loading && <div className={styles.Loader}><Spinner color={(props.text && props.color) || (props.outline && props.color) ? props.color : (['primary', 'purple', 'green', 'red', 'orange', 'yellow', 'blue', 'gray', 'dark'].includes(props.color) ? 'white' : null)} /></div>}
				{props.counter && <div className={styles.Counter}>{props.counter}</div>}
			</div>
		</CustomTag>
	)
}

export default Button;